export interface LoginFromInterface {
  email: string;
  password: string;
  errors: Array<string>;
  keeplogin: boolean | false;
  success: string;
}

export interface DogInterface {
  breedName: string;
  dateOfVaccine: string;
  dogName: string;
  file: string;
}
export enum HasDog {
  YES = 1,
  NO = 2,
}
export interface ForgetPasswordInterface {
  email: string;
  id: string;
}
export interface RegisterFormInterface {
  id: string;
  email: string;
  confirmEmail:string;
  password: string;
  mobileNo: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  englishFirstName: string;
  englishLastName: string;
  pincode: string;
  state: string;
  district: string;
  city: string;
  apartment: any;
  hasDog: HasDog;
  dogDetail: DogInterface;
  dogDetailList: DogInterface[];
  mobileNumber: string;
}
export interface AllStateInterface {
  country: string;
}
export interface UpdatePasswordInterface {
  id: string;
  password: string;
  newPassword: string;
}

export interface EmailVerificationInterface {
  statusCode: boolean;
  message: string;
}
export interface AuthState {
  loginForm: LoginFromInterface;
  updatePasswordForm: UpdatePasswordInterface;
  emailVerification: EmailVerificationInterface;
  registerForm: RegisterFormInterface;
  userData: {
    id: string;
    firstName: string;
    lastName: string;
    profilePic: string;
    email: string;
    username: string;
    usertype: string;
  };
  loading: boolean;
  token: string;
  role: number;
  allStateList: AllStateInterface[];
  isLogin: boolean;
  forgetpasswordFormData: ForgetPasswordInterface;
}

export enum ClinicErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
}

export type InitialAuthState = AuthState;
