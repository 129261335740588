import { PayloadAction } from "@reduxjs/toolkit";

import { set } from "lodash";
import { UploaderState } from "../types";
import { createSlice } from "../../../utils/@reduxjs/toolkit";

export const initialState: UploaderState = {
  imageUploadeProgress: 0,
  frontPageSearch: {
    date: "",
    numberOfGuest: [],
    adult: 0,
    large: 0,
    smallDog: 0,
    dog: 0,
  },
};

export const useClinicSlice = createSlice({
  name: "UploaderState",
  initialState,
  reducers: {
    updateImageProgress: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.imageUploadeProgress = action.payload.value;
    },

    updatefrontPageSearchFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `frontPageSearch.${action.payload.key}`, action.payload.value);
    },
    pushnumberOfGuestData: (state, action: PayloadAction<any>) => {
      state.frontPageSearch.numberOfGuest.push();
    },
  },
});

export const { reducer, actions, name: SliceKey } = useClinicSlice;
