import errorCode from "../../hooks/errorCode";
import CustomToast from "../CustomToast";

export default function CatchBlockFunction(error: any) {
  if (errorCode(error?.response?.data.statusCode)) {
    CustomToast(error?.response?.data.message, "ERROR");
  } else {
    CustomToast(error?.response?.data?.message, "ERROR");
  }
  if (
    error.message !== "Cannot read properties of undefined (reading 'context')"
  ) {
    if (
      error.message !== "undefined is not an object (evaluating 'e.context')"
    ) {
      if (error.message !== "e is undefined") {
        if (
          error.message !== "Cannot read properties of undefined (reading '0')"
        ) {
          if (error.message !== "call: argument fn is undefined or null") {
            if (
              error.message !==
              "Cannot read properties of undefined (reading 'results')"
            ) {
              if (error?.response?.data) {
                if (error?.response?.data.statusCode !== 401) {
                  if (error?.response?.data.errors) {
                    let dataerrer = error?.response?.data.errors.map(
                      (item: any) => {
                        return {
                          name: item.property,
                          message: item.message,
                        };
                      }
                    );
                    for (let index = 0; index < dataerrer.length; index++) {
                      const element = dataerrer[index];
                      CustomToast(element.message, "ERROR");
                    }
                    let dataerrer1 = error?.response?.data.errors.map(
                      (item: any) => {
                        return {
                          name: item.property,
                          message: item.constraints.isEmail,
                        };
                      }
                    );
                    for (let index = 0; index < dataerrer1.length; index++) {
                      const element = dataerrer1[index];
                      CustomToast(element.message, "ERROR");
                    }
                  } else {
                    CustomToast(error?.response?.data.message, "ERROR");
                  }
                } else {
                  localStorage.removeItem("token");
                }
              } else {
                CustomToast(error.message, "ERROR");
              }
            }
          }
        }
      }
    }
  }
}
