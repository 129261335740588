import { createSlice } from "../../../utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  AllStateInterface,
  AuthState,
  EmailVerificationInterface,
  HasDog,
  RegisterFormInterface,
} from "../types";
import { LoginMessage } from "../../../Messages";
import { set } from "lodash";

export const initialState: AuthState = {
  loading: false,
  loginForm: {
    email: "",
    password: "",
    errors: [],
    success: "",
    keeplogin: false,
  },
  emailVerification: { statusCode: false, message: "" },
  updatePasswordForm: { id: "", password: "", newPassword: "" },
  registerForm: {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    englishFirstName: "",
    lastName: "",
    englishLastName: "",
    pincode: "",
    state: "",
    district: "",
    city: "",
    apartment: "",
    hasDog: HasDog.NO,
    dogDetail: {
      breedName: "",
      dateOfVaccine: "",
      dogName: "",
      file: "",
    },
    dogDetailList: [],
    id: "",
    mobileNo: "",
    mobileNumber: "",
    confirmEmail: ""
  },
  userData: {
    id: "",
    firstName: "",
    lastName: "",
    profilePic: "",
    email: "",
    username: "",
    usertype: "",
  },
  token: localStorage.getItem("token") || "",
  isLogin: false,
  role: 1,
  allStateList: [],
  forgetpasswordFormData: {
    email: "",
    id: "",
  },
};

export const useClinicSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    updateForgetPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `forgetpasswordFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    clearForgetPasswordFrom: (state) => {
      state.forgetpasswordFormData.id = "";
      state.forgetpasswordFormData.email = "";
    },

    setisLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUpdateTocken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },

    sendForgetPassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doRegisterSelf: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doUpdateUserInfo: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doLogin: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    doFindPincode: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateLoginFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `loginForm.${action.payload.key}`, action.payload.value);
    },
    updateRegisterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `registerForm.${action.payload.key}`, action.payload.value);
    },

    updateRegisterdogDetailFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `registerForm.dogDetail.${action.payload.key}`,
        action.payload.value
      );
    },
    clearRegisterFrom: (state) => {
      state.registerForm.id = "";
      state.registerForm.email = "";
      state.registerForm.password = "";
      state.registerForm.confirmPassword = "";
      state.registerForm.firstName = "";
      state.registerForm.lastName = "";
      state.registerForm.englishFirstName = "";
      state.registerForm.englishLastName = "";
      state.registerForm.mobileNumber = "";
      state.registerForm.pincode = "";
      state.registerForm.apartment = "";
      state.registerForm.city = "";
      state.registerForm.district = "";
      state.registerForm.state = "";
      state.registerForm.hasDog = HasDog.NO;
      state.registerForm.dogDetailList = [];
    },
    setStateList: (state, action: PayloadAction<Array<AllStateInterface>>) => {
      state.allStateList = action.payload;
    },
    setRegisterFormData: (
      state,
      action: PayloadAction<RegisterFormInterface>
    ) => {
      state.registerForm = action.payload;
    },

    pushDogListData: (state) => {
      state.registerForm.dogDetailList.push();
    },
    dogetStateList: (state) => {},
    dogetUserData: (state) => {},
    clearRegisterDogFrom: (state) => {
      state.registerForm.dogDetail.breedName = "";
      state.registerForm.dogDetail.dateOfVaccine = "";
      state.registerForm.dogDetail.file = "";
      state.registerForm.dogDetail.dogName = "";
    },
    doLogOut: (state) => {},
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (
      state,
      action: PayloadAction<{ data: any; signedUrl: string; callback: any }>
    ) => {},
    getUserProfile: (state) => {},
    setProfileData: (
      state,
      action: PayloadAction<{
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        usertype: string;
      }>
    ) => {
      state.userData.id = action.payload.id;
      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.email = action.payload.email;
      state.userData.usertype = action.payload.usertype;
    },
    updateUpdatePasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `updatePasswordForm.${action.payload.key}`,
        action.payload.value
      );
    },
    doUpdatePassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doVerifiedEmailIndex: (
      state,
      action: PayloadAction<{ token: string; callback: () => void }>
    ) => {},
    setEmailConfirmation: (
      state,
      action: PayloadAction<EmailVerificationInterface>
    ) => {
      state.emailVerification = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
