/** @format */

import "./App.css";
import loadable from "@loadable/component";
import { Close } from "@mui/icons-material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { userRepoSaga } from "./Pages/Auth/redux/saga";
import {
  sliceKey as userSliceKey,
  reducer as userReducer,
} from "./Pages/Auth/redux/slice";
import { myPageRepoSaga } from "./Redux/MyPage/redux/saga";
import {
  sliceKey as myPageSliceKey,
  reducer as myPageReducer,
} from "./Redux/MyPage/redux/slice";
import { UploaderSaga } from "./Redux/New/redux/saga";
import {
  SliceKey as UploaderSliceKey,
  reducer as UploaderReducer,
} from "./Redux/New/redux/slice";
import { BookingRepoSaga } from "./Pages/Booking/redux/saga";
import {
  sliceKey as BookingSliceKey,
  reducer as BookingReducer,
} from "./Pages/Booking/redux/slice";
import { contactUsRepoSaga } from "./Redux/ContactUs/redux/saga";
import {
  SliceKey as ContactUsSliceKey,
  reducer as ContactUsReducer,
} from "./Redux/ContactUs/redux/slice";

import {
  SliceKey as GallerySliceKey,
  reducer as GalleryReducer,
} from "./Redux/Gallery/redux/slice";
import { GalleryRepoSaga } from "./Redux/Gallery/redux/saga";
import {
  SliceKey as NewsTopicsSliceKey,
  reducer as NewsTopicsReducer,
} from "./Redux/NewsTopics/redux/slice";
import { NewsTopicRepoSaga } from "./Redux/NewsTopics/redux/saga";
const MyPage = loadable(() => import("./Pages/MyPage"));
const Homepage = loadable(() => import("./Pages/Homepage"));
const Login = loadable(() => import("./Pages/Auth/Login"));
const ForgotPassword = loadable(() => import("./Pages/Auth/ForgotPassword"));
const Register = loadable(() => import("./Pages/Auth/Register"));
const RegistrationThankyou = loadable(
  () => import("./Pages/RegistrationThankyou")
);
const BookingConfirmationThankyou = loadable(
  () => import("./Pages/Booking/BookingConfirmationThankyou")
);
const BookingConfirmation = loadable(
  () => import("./Pages/Booking/BookingConfirmation")
);
const Booking = loadable(() => import("./Pages/Booking/Booking"));
const Play = loadable(() => import("./Pages/Features/Play"));
const Adventure = loadable(() => import("./Pages/Features/Adventure"));
const Eat = loadable(() => import("./Pages/Features/Eat"));
const Stay = loadable(() => import("./Pages/Features/Stay"));
const Totonou = loadable(() => import("./Pages/Features/Totonou"));
const Concepts = loadable(() => import("./Pages/Concepts"));

const NewsList = loadable(() => import("./Pages/News/NewsList"));
const NewsDetail = loadable(() => import("./Pages/News/NewsDetail"));
const Privacy = loadable(() => import("./Pages/Privacy"));

const AmazonePay = loadable(() => import("./Pages/amazonePay"));

const Terms = loadable(() => import("./Pages/Terms"));
const QA = loadable(() => import("./Pages/QA"));
const Company = loadable(() => import("./Pages/Company"));
const Pool = loadable(() => import("./Pages/Pool"));
const Bbq = loadable(() => import("./Pages/Bbq"));
const Access = loadable(() => import("./Pages/Access"));
const Information = loadable(() => import("./Pages/Information"));
const UpdatePassword = loadable(() => import("./Pages/Auth/UpdatePassword"));
const PrivacyPolicy = loadable(() => import("./Pages/PrivacyPolicy"));
const Browser = loadable(() => import("./Pages/Browser"));
const GalleryDetails = loadable(() => import("./Pages/GalleryDetails"));
const Enjoy = loadable(() => import("./Pages/Enjoy"));
const Contact = loadable(() => import("./Pages/Contact"));
const BookingConfirmationSorry = loadable(
  () => import("./Pages/Booking/BookingConfirmationSorry")
);
const EmailConfirmaction = loadable(() => import("./Pages/EmailConfirmaction"));
const UserGuide = loadable(() => import("./Pages/UserGuide"));

function App() {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: userRepoSaga });
  useInjectReducer({ key: myPageSliceKey, reducer: myPageReducer });
  useInjectSaga({ key: myPageSliceKey, saga: myPageRepoSaga });
  useInjectReducer({
    key: UploaderSliceKey,
    reducer: UploaderReducer,
  });
  useInjectSaga({
    key: UploaderSliceKey,
    saga: UploaderSaga,
  });
  useInjectReducer({
    key: BookingSliceKey,
    reducer: BookingReducer,
  });
  useInjectSaga({
    key: BookingSliceKey,
    saga: BookingRepoSaga,
  });
  useInjectReducer({
    key: ContactUsSliceKey,
    reducer: ContactUsReducer,
  });
  useInjectSaga({
    key: ContactUsSliceKey,
    saga: contactUsRepoSaga,
  });
  useInjectReducer({
    key: GallerySliceKey,
    reducer: GalleryReducer,
  });
  useInjectSaga({
    key: GallerySliceKey,
    saga: GalleryRepoSaga,
  });
  useInjectReducer({
    key: NewsTopicsSliceKey,
    reducer: NewsTopicsReducer,
  });
  useInjectSaga({
    key: NewsTopicsSliceKey,
    saga: NewsTopicRepoSaga,
  });
  const CloseButton = ({ closeToast }: any) => (
    <Close onClick={closeToast} className='toast-close' width='20' />
  );
  return (
    <>
      <BrowserRouter>
        <ToastContainer closeButton={CloseButton} />
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/updatepassword' element={<UpdatePassword />} />
          <Route path='/register' element={<Register />} />
          <Route
            path='/registrationthankyou'
            element={<RegistrationThankyou />}
          />
          <Route
            path='/bookingconfirmationthankyou'
            element={<BookingConfirmationThankyou />}
          />
          <Route path='/mypage' element={<MyPage />} />
          <Route
            path='/bookingconfirmation'
            element={<BookingConfirmation />}
          />
          <Route path='/booking' element={<Booking />} />
          <Route path='/play' element={<Play />} />
          <Route path='/adventure' element={<Adventure />} />
          <Route path='/eat' element={<Eat />} />
          <Route path='/stay' element={<Stay />} />
          <Route path='/totonou' element={<Totonou />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/gallery' element={<GalleryDetails />} />
          <Route path='/concepts' element={<Concepts />} />
          <Route path='/qa' element={<QA />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/company' element={<Company />} />
          <Route path='/pool' element={<Pool />} />
          <Route path='/bbq' element={<Bbq />} />
          <Route path='/access' element={<Access />} />
          <Route path='/information' element={<Information />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/enjoy' element={<Enjoy />} />
          <Route path='/bookingfailed' element={<BookingConfirmationSorry />} />
          <Route path='/emailconfirmation' element={<EmailConfirmaction />} />
          <Route path='/userguide' element={<UserGuide />} />
          <Route path='/newslist' element={<NewsList />} />
          <Route path='/newsdetail' element={<NewsDetail />} />
          <Route path='/newsdetail/:id' element={<NewsDetail />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/browser' element={<Browser />} />
          <Route path='/amazonePay' element={<AmazonePay />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
