import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.newsTopicState) {
    return state.newsTopicState;
  } else {
    return initialState;
  }
};
export const selectFeaturedList = createSelector(
  [selectDomain],
  (state) => state.FeaturedList
);
export const selectNewsTopicList = createSelector(
  [selectDomain],
  (state) => state.newsTopicList
);
export const selectNewsTopicTitleList = createSelector(
  [selectDomain],
  (state) => state.categoryList
);
export const selectNewsDetails = createSelector(
  [selectDomain],
  (state) => state.newsDetails
);

export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
