import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import CustomToast from "../../../Components/CustomToast";
import CatchBlockFunction from "../../../Components/hooks/CatchBlockFunction";
import { AxiosResponse } from "axios";
import {
  UpdateLoginIdRequest,
  UpdatePasswordRequest,
  doCencleBooking,
  doGetuserBookingDetails,
  doGetuserLatestBookingDetails,
  dodeleteDogDetail,
} from "../../../utils/request";
import {
  UserBookinglistInterface,
  changeLoginIdInterface,
  chnageCurrentPasswordInterface,
} from "../types";
import {
  SelectPageSize,
  selectChnageCurrentPasswordForm,
  selectChnageLoginIdForm,
  SelectPageNo,
  selectUserBookinglist,
} from "./selector";

export function* doUpdatePasswordRequest(action: {
  payload: { callback: any };
}) {
  // const regex =
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  yield delay(500);
  const form: chnageCurrentPasswordInterface = yield select(
    selectChnageCurrentPasswordForm
  );
  if (form.password.length === 0) {
    CustomToast("パスワードを入力してください。", "ERROR");
    return;
  }
  // if (form.password.length > 0) {
  //   if (!regex.test(form.password)) {
  //     CustomToast("有効なパスワードを入力してください。", "ERROR");
  //     return;
  //   }
  // }
  if (form.newPassword.length === 0) {
    CustomToast("新しいパスワードを入力してください。", "ERROR");
    return;
  }
  // if (form.newPassword.length > 0) {
  //   if (!regex.test(form.newPassword)) {
  //     CustomToast("有効なパスワードを入力してください。", "ERROR");
  //     return;
  //   }
  // }
  try {
    const response: AxiosResponse = yield call(UpdatePasswordRequest, form);
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.clearUpdatePasswordFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateLoginIdRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: changeLoginIdInterface = yield select(selectChnageLoginIdForm);
  if (form.email.length === 0) {
    CustomToast("メールアドレスを入力してください。", "ERROR");
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    CustomToast("有効なメールアドレスを入力してください。", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(UpdateLoginIdRequest, form);
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.clearUpdateLoginIdFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetUserBookingListRequest() {
  yield delay(500);
  // ;

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const List: UserBookinglistInterface[] = yield select(
      selectUserBookinglist
    );

    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetuserBookingDetails,
      `?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (pageNo == 1) {
      yield put(
        actions.setTotalRow(
          response.data.data ? response.data.data.meta.total : 0
        )
      );
      yield put(actions.setUserBookingList(response.data.data.results));
    } else {
      const concatdata = List.concat(response.data.data.results);
      yield put(actions.setTotalRow(response.data.data.meta.total));
      yield put(actions.setUserBookingList(concatdata));
    }
    yield put(actions.setHasMore(response.data.data.results.length > 0));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetUserlatestBookingRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetuserLatestBookingDetails);
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setUserLatestBookingList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doCencleBookingRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      doCencleBooking,
      `?bookingId=${action.payload}`
    );
    if(response.data.message == "予約のキャンセルはできませんので、管理者にご連絡ください。" && !response.data.statusCode){
      yield put(actions.setshowModel(true));
      return
    }
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doDeleteDogRequest(action: { payload: { id:string , callback: () => void} }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      dodeleteDogDetail,
      action.payload.id
    );
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* myPageRepoSaga() {
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
  yield takeLatest(actions.doUpdateLoginId, doUpdateLoginIdRequest);
  yield takeLatest(actions.doUserBookingList, doGetUserBookingListRequest);
  yield takeLatest(
    actions.doUserLatestBookingList,
    doGetUserlatestBookingRequest
  );
  yield takeLatest(actions.doCencleBooking, doCencleBookingRequest);
  yield takeLatest(actions.dodeleteDogDetail, doDeleteDogRequest); 
}
