import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.galleryState) {
    return state.galleryState;
  } else {
    return initialState;
  }
};
export const selectGalleryFeaturedList = createSelector(
  [selectDomain],
  (state) => state.galleryFeaturedList
);
export const selectGalleryList = createSelector(
  [selectDomain],
  (state) => state.galleryList
);
export const selectGalleryTitleList = createSelector(
  [selectDomain],
  (state) => state.galleryTitleList
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
