import { toast } from "react-toastify";
const CustomToast = (toastMessage: any, type: any) => {
  if (type === "SUCCESS") {
    return toast.success(toastMessage, {
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 100,
    });
  } else if (type === "ERROR") {
    return toast.error(toastMessage, {
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 100,
    });
  } else if (type === "WARNING") {
    return toast.warn(toastMessage, {
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 100,
    });
  } else if (type === "INFO") {
    return toast.info(toastMessage, {
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    return toast.success(toastMessage, {
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
export default CustomToast;
