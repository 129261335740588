import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import CustomToast from "../../../Components/CustomToast";
import CatchBlockFunction from "../../../Components/hooks/CatchBlockFunction";
import { AxiosResponse } from "axios";
import { doAddContactUs } from "../../../utils/request";
import { selectContactUsForm } from "./selector";
import { ContactUsFormInterface } from "../types";

export function* doAddContactUsRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ContactUsFormInterface = yield select(selectContactUsForm);
  ;
  if (form.inquiryType.length === 0) {
    CustomToast("お問い合わせ項目を入れてください！", "ERROR");
    return;
  }
  if (form.name.length == 0) {
    CustomToast("お名前を入れてください！", "ERROR");
    return;
  }
  if (form.englishName.length === 0) {
    CustomToast("お名前を入れてください！", "ERROR");
    return;
  }
  if (form.email.length === 0) {
    CustomToast("メールアドレスが必要です！", "ERROR");
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    CustomToast("有効なメールアドレスを入力してください。", "ERROR");
    return;
  }
  if (form.description.length === 0) {
    CustomToast("お問い合わせ内容を入れてください！", "ERROR");
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddContactUs, form);
    yield put(actions.setLoading(false));
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.clearContactUsFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* contactUsRepoSaga() {
  yield takeLatest(actions.doSendContactus, doAddContactUsRequest);
}
