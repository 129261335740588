import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  selectForgetPaswordFrom,
  selectLoginForm,
  selectRegisterForm,
  selectUpdatePasswordForm,
} from "./selector";
import { actions } from "./slice";
import CustomToast from "../../../Components/CustomToast";
import {
  ForgetPasswordInterface,
  HasDog,
  LoginFromInterface,
  RegisterFormInterface,
  UpdatePasswordInterface,
} from "../types";
import CatchBlockFunction from "../../../Components/hooks/CatchBlockFunction";
import { AxiosResponse } from "axios";
import {
  ForgetPasswordRequest,
  LoginRequest,
  UserSignUpRequest,
  getImageRequest,
  getStateRequest,
  getUserDataRequestAPI,
  uplodeImageRequest,
  MeProfileRequest,
  UserDetailUpdateRequest,
  UpdatePasswordRequest,
  EmailVerifiedIndexDetail,
} from "../../../utils/request";

export function* SignUpRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: RegisterFormInterface = yield select(selectRegisterForm);
  if (form.email.length === 0) {
    CustomToast("メールアドレスを入力してください。", "ERROR");
    return;
  }
  if (form.confirmEmail.length === 0) {
    CustomToast(
      "メールアドレスと確認用メールアドレスが一致しません。",
      "ERROR"
    );
    return;
  }
  if (form.password.length === 0) {
    CustomToast("パスワードを入力してください。", "ERROR");
    return;
  }
  if (form.confirmPassword.length === 0) {
    CustomToast("確認のため、もう一度パスワードを入力してください。", "ERROR");
    return;
  }
  if (form.firstName.length === 0) {
    CustomToast("名前を入力してください", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("姓を入力してください", "ERROR");
    return;
  }
  if (form.englishFirstName.length === 0) {
    CustomToast("お名前をふりがなでご入力ください。", "ERROR");
    return;
  }
  if (form.englishLastName.length === 0) {
    CustomToast("姓をふりがなで入力してください。", "ERROR");
    return;
  }
  if (form.mobileNumber.length === 0) {
    CustomToast("電話番号を入力してください。", "ERROR");
    return;
  }
  if (form.pincode?.length === 0) {
    CustomToast("郵便番号を入力してください。", "ERROR");
    return;
  }

  // if (form.hasDog == HasDog.YES) {
  //   for (let index = 0; index < form.dogDetailList.length; index++) {
  //     const element = form.dogDetailList[index];
  //     if (element.breedName.length == 0) {
  //       CustomToast("犬種の選択画面から選んでください！", "ERROR");
  //       return;
  //     }
  //     if (element.dateOfVaccine == "") {
  //       CustomToast("ワクチン接種日を入れてください！", "ERROR");
  //       return;
  //     }
  //     if (element.dogName == "") {
  //       CustomToast("犬の名前を入れてください！", "ERROR");
  //       return;
  //     }
  //     if (element.file == "") {
  //       CustomToast("ワクチンの画像を追加してください!", "ERROR");
  //       return;
  //     }
  //   }
  // }
  yield put(actions.setloading(true));
  try {
    const response: AxiosResponse = yield call(UserSignUpRequest, form);
    yield put(actions.setloading(false));
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.clearRegisterFrom());
    yield put(actions.setloading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setloading(false));
    CatchBlockFunction(error);
  }
}
export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: LoginFromInterface = yield select(selectLoginForm);

  if (form.email.length === 0) {
    CustomToast("メールアドレスを入力してください", "ERROR");
    return;
  }
  if (form.password.length === 0) {
    CustomToast(
      "数字、大英文字、小英文字、記号を使ったパスワードにしてください！",
      "ERROR"
    );
    return;
  }
  let data = {
    email: form.email,
    password: form.password,
  };

  try {
    const response: AxiosResponse = yield call(LoginRequest, data);
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "Error");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setisLogin(true));
    yield put(actions.setUpdateTocken(response.data.data.accessToken));
    yield put(actions.getUserProfile());
    yield call(action?.payload?.callback(response.data.data.accessToken));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getStateListRequest() {
  yield delay(500);
  const form: RegisterFormInterface = yield select(selectRegisterForm);
  try {
    const response: AxiosResponse = yield call(getStateRequest, form.pincode);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setStateList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* ForgePasswordDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ForgetPasswordInterface = yield select(selectForgetPaswordFrom);
  if (form.email.length == 0) {
    CustomToast("有効なメールアドレスを入力してください", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(ForgetPasswordRequest, form);
    if (response.data.statusCode == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* meLogOutRequest() {
  localStorage.removeItem("token");
  yield put(actions.setisLogin(false));
  localStorage.clear();
  window.location.reload();
}
export function* FileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getImageRequest,
      action.payload.data
    );
    if (response.data.statusCode == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(
      action?.payload?.callback(
        response.data.signedUrl,
        response.data.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: {
  payload: { data: any; signedUrl: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeImageRequest,
      action.payload.signedUrl,
      action.payload.data
    );
    if (response.data.statusCode == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateUserInfoRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: RegisterFormInterface = yield select(selectRegisterForm);
  if (form.email.length === 0) {
    CustomToast(" メールアドレスを入力してください。", "ERROR");
    return;
  }
  if (form.firstName.length === 0) {
    CustomToast("名前を入力してください", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("姓を入力してください", "ERROR");
    return;
  }
  if (form.englishFirstName.length === 0) {
    CustomToast("お名前をふりがなでご入力ください。", "ERROR");
    return;
  }
  if (form.englishLastName.length === 0) {
    CustomToast("姓をふりがなで入力してください。", "ERROR");
    return;
  }
  if (form.mobileNumber.length === 0) {
    CustomToast("電話番号を入力してください。", "ERROR");
    return;
  }
  if (form.pincode?.length === 0) {
    CustomToast("郵便番号を入力してください。", "ERROR");
    return;
  }
  // if (form.hasDog == HasDog.YES) {
  //   for (let index = 0; index < form.dogDetailList.length; index++) {
  //     const element = form.dogDetailList[index];
  //     if (element.breedName.length == 0) {
  //       CustomToast("犬種の選択画面から選んでください！", "ERROR");
  //       return;
  //     }
  //     if (element.dateOfVaccine == "") {
  //       CustomToast("ワクチン接種日を入れてください！", "ERROR");
  //       return;
  //     }
  //     if (element.dogName == "") {
  //       CustomToast("犬の名前を入れてください！", "ERROR");
  //       return;
  //     }
  //     if (element.file == "") {
  //       CustomToast("ワクチンの画像を追加してください!", "ERROR");
  //       return;
  //     }
  //   }
  // }
  try {
    const response: AxiosResponse = yield call(UserDetailUpdateRequest, form);
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getUserDataRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getUserDataRequestAPI);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setRegisterFormData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

//***** me request */
export function* meProfileRequest() {
  yield delay(500);
  if (localStorage.getItem("token")) {
    try {
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }
      yield put(actions.setisLogin(true));
      yield put(
        actions.setProfileData({
          id: response.data.data._id,
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          email: response.data.data.email,
          usertype: response.data.data.role,
        })
      );
      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  }
}
export function* doUpdatePasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: UpdatePasswordInterface = yield select(selectUpdatePasswordForm);
  if (form.password.length === 0) {
    CustomToast("メールアドレスを入力してください", "ERROR");
    return;
  }
  if (form.newPassword.length === 0) {
    CustomToast(
      "数字、大英文字、小英文字、記号を使ったパスワードにしてください！",
      "ERROR"
    );
    return;
  }
  try {
    const response: AxiosResponse = yield call(UpdatePasswordRequest, form);
    if (response && !response.data.statusCode) {
      CustomToast(response.data.message, "Error");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddMailVerificatonIndexRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      EmailVerifiedIndexDetail,
      `?accessToken=${action.payload.token}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setEmailConfirmation(response.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* userRepoSaga() {
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.doRegisterSelf, SignUpRequest);
  yield takeLatest(actions.doUpdateUserInfo, doUpdateUserInfoRequest);
  yield takeLatest(actions.doFindPincode, getStateListRequest);
  yield takeLatest(actions.sendForgetPassword, ForgePasswordDataRequest);
  yield takeLatest(actions.doLogOut, meLogOutRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
  yield takeLatest(actions.dogetUserData, getUserDataRequest);
  yield takeLatest(actions.getUserProfile, meProfileRequest);
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
  yield takeLatest(
    actions.doVerifiedEmailIndex,
    AddMailVerificatonIndexRequest
  );
}
