import { PayloadAction } from "@reduxjs/toolkit";

import { set } from "lodash";
import { ContactUsState } from "../types";
import { createSlice } from "../../../utils/@reduxjs/toolkit";

export const initialState: ContactUsState = {
  contactUsForm: {
    id: "",
    inquiryType: "",
    name: "",
    englishName: "",
    email: "",
    mobileNumber: "",
    postalCode: "",
    address: "",
    description: "",
  },
  loading: false
};

export const useClinicSlice = createSlice({
  name: "contactUsState",
  initialState,
  reducers: {
    updateContactUsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `contactUsForm.${action.payload.key}`, action.payload.value);
    },

    doSendContactus: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    clearContactUsFrom: (state) => {
      state.contactUsForm.id = "";
      state.contactUsForm.inquiryType = "";
      state.contactUsForm.name = "";
      state.contactUsForm.englishName = "";
      state.contactUsForm.email = "";
      state.contactUsForm.mobileNumber = "";
      state.contactUsForm.postalCode = "";
      state.contactUsForm.address = "";
      state.contactUsForm.description = "";
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { reducer, actions, name: SliceKey } = useClinicSlice;
