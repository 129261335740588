import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  MyPageState,
  UserBookinglistInterface,
  changeLoginIdInterface,
  chnageCurrentPasswordInterface,
} from "../types";

export const initialState: MyPageState = {
  chnageCurrentPassword: {
    password: "",
    newPassword: "",
    id: "",
  },
  chnageLoginId: {
    id: "",
    email: "",
  },
  changeMemberInfo: {
    id: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    accentsFirst: "",
    accentsSecond: "",
    pincode: "",
    state: "",
    district: "",
    city: "",
    apartment: "",
  },
  loading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  userBookinglist: [],
  userLatestBookinglist: {},
  hasMore: false,
  showModel: false
};

export const useClinicSlice = createSlice({
  name: "myPageState",
  initialState,
  reducers: {
    updateCurrentPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `chnageCurrentPassword.${action.payload.key}`,
        action.payload.value
      );
    },
    doUpdatePassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    clearUpdatePasswordFrom: (state) => {
      state.chnageCurrentPassword.password = "";
      state.chnageCurrentPassword.newPassword = "";
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    setPasswordFormData: (
      state,
      action: PayloadAction<chnageCurrentPasswordInterface>
    ) => {
      state.chnageCurrentPassword = action.payload;
    },
    setLoginFormData: (
      state,
      action: PayloadAction<changeLoginIdInterface>
    ) => {
      state.chnageLoginId = action.payload;
    },
    clearUpdateLoginIdFrom: (state) => {},
    updateLoginIdFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `chnageLoginId.${action.payload.key}`, action.payload.value);
    },
    doUpdateLoginId: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    clearUpdateEmailFrom: (state) => {
      state.chnageLoginId.id = "";
      state.chnageLoginId.email = "";
    },
    getPasswordFormData: (state) => {},
    getLoginFormData: (state) => {},
    doUserBookingList: (state) => {},
    doUserLatestBookingList: (state) => {},
    dodeleteDogDetail: (state, action: PayloadAction<{
      id:string , callback: () => void
    }>) => {},
    doCencleBooking: (state, action: PayloadAction<string>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setshowModel: (state, action: PayloadAction<boolean>) => {
      state.showModel = action.payload;
    },
    setUserBookingList: (
      state,
      action: PayloadAction<Array<UserBookinglistInterface>>
    ) => {
      state.userBookinglist = action.payload;
    },
    setUserLatestBookingList: (state, action: PayloadAction<any>) => {
      state.userLatestBookinglist = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
