import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  console.log("state.contactUsState", state);
  if (state && state.contactUsState) {
    return state.contactUsState;
  } else {
    return initialState;
  }
};
export const selectContactUsForm = createSelector(
  [selectDomain],
  (state) => state.contactUsForm
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);