import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state) {
    return state.authState;
  } else {
    return initialState;
  }
};
export const selectLoginForm = createSelector(
  [selectDomain],
  (state) => state.loginForm
);
export const selectRegisterForm = createSelector(
  [selectDomain],
  (state) => state.registerForm
);
export const selectAllStateListData = createSelector(
  [selectDomain],
  (state) => state.allStateList
);
export const selectdogDetailForm = createSelector(
  [selectDomain],
  (state) => state.registerForm.dogDetail
);
export const selectForgetPaswordFrom = createSelector(
  [selectDomain],
  (state) => state.forgetpasswordFormData
);
export const selectisLogin = createSelector(
  [selectDomain],
  (state) => state.isLogin
);
export const selectdogDetailFormlist = createSelector(
  [selectDomain],
  (state) => state.registerForm.dogDetailList
);
export const selectuserData = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const selectUpdatePasswordForm = createSelector(
  [selectDomain],
  (state) => state.updatePasswordForm
);
export const SelectEmailVerification = createSelector(
  [selectDomain],
  (state) => state.emailVerification
);
export const selectloading = createSelector(
  [selectDomain],
  (state) => state.loading
);